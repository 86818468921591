import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ContactLoadedEvent } from 'src/modules/diversite/components/contacts/contact-search-profile/contact-search-profile.component';
import { Contact } from 'src/modules/diversite/model/contact';
import { ContactPerspective } from 'src/modules/diversite/model/contactPerspective';
import { SearchResultsGroup } from 'src/modules/diversite/model/search-results';
import { ContactHighlight, ContactResult } from 'src/modules/diversite/services/elasticsearch.service';

@Component({
    selector: 'diversite-search-result-group',
    templateUrl: './search-result-group.component.html',
    styleUrl: './search-result-group.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultGroupComponent {
    @Input() group: SearchResultsGroup;
    @Input() contactWidth: number;
    @Input() infoSize: number;
    @Input() classificationId: string;
    @Input() shownAttributes: string[];

    @Output() contactLoaded = new EventEmitter<ContactLoadedEvent>();
    @Output() toggleSelection = new EventEmitter<Contact>();
    @Output() viewProfile = new EventEmitter<ContactPerspective>();
    @Output() editContact = new EventEmitter<Contact>();
    @Output() openFormPane = new EventEmitter<OpenFormPaneEvent>();

    open = true;

    trackByContactResult(_: number, contactData: ContactResult): string {
        return contactData.contact ? contactData.contact.id : contactData.contactId;
    }

    toggle(): void {
        this.open = !this.open;
    }

    contactHeight(contactHighlights: ContactHighlight[]): number {
        if (this.shownAttributes?.length > 0 || contactHighlights?.length > 0) {
            return this.contactWidth * 1.2 + this.infoSize;
        }
        return this.contactWidth * 1.2;
    }

    onContactLoaded(event: ContactLoadedEvent): void {
        this.contactLoaded.emit(event)
    }

    onToggleSelection(contact: Contact): void {
        this.toggleSelection.emit(contact)
    }

    onViewProfile(cp: ContactPerspective): void {
        this.viewProfile.emit(cp);
    }

    onEditContact(contact: Contact): void {
        this.editContact.emit(contact)
    }

    onOpenFormPane(formId: string, contactId: string): void {
        this.openFormPane.emit({ contactId, formId });
    }
}

export interface OpenFormPaneEvent {
    contactId: string;
    formId: string;
}