import { Injectable } from '@angular/core';
import {
    Action,
    AngularFirestore,
    DocumentChangeAction,
    DocumentSnapshot,
    QueryDocumentSnapshot,
    QuerySnapshot
} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

import { Timestamp } from 'firebase/firestore';
import { Project, ProjectStatus } from '../model/project';
import { ProjectConfigPublicFile, ProjectFormPublicDescriptor, PublicProjectConfiguration } from '../model/public-project-configuration';
import { ContextNodeService } from './context-node.service';
import { TranslatableLabel } from './data-catalog.service';


@Injectable({
    providedIn: "root",
})
export class ProjectService {
    constructor(
        private authService: AuthService,
        private contextNodeService: ContextNodeService,
        private db: AngularFirestore
    ) { }

    projects(options?: ProjectQueryOption): Observable<Project[]> {
        if (this.authService.isLoggedIn) {
            const collection = this.db.collection("projects", (ref) => {
                let queryRef = ref as any;
                if (options && (options.archived === true || options.archived === false)) {
                    queryRef = queryRef.where("archived", "==", options.archived);
                }
                if (options && (options.deleted === true || options.deleted === false)) {
                    queryRef = queryRef.where("deleted", "==", options.deleted);
                }

                queryRef = queryRef.orderBy("created", "desc");

                return queryRef;
            });



            if (options && options.listen) {

                return this.contextNodeService.nodeView({ listen: true }).pipe(
                    switchMap(contextNodes => {
                        const activeProjects = contextNodes.map(cn => cn.contextData.projectId).filter(_ => _ ? true : false);
                        return collection.snapshotChanges().pipe(
                            map((documents: DocumentChangeAction<unknown>[]) => {
                                return documents
                                    .map((doc) => {
                                        if (doc.payload.doc.exists) {
                                            const data = doc.payload.doc.data() as ProjectDTO;
                                            return this.projectDtoToProject(
                                                {
                                                    ...(data as any),
                                                    id: doc.payload.doc.id,
                                                    created: data.created
                                                        ? (data.created as firebase.firestore.Timestamp).toDate()
                                                        : null,
                                                },
                                                activeProjects
                                            );
                                        }
                                        return undefined;
                                    })
                                    .filter((p) => p);
                            })
                        );
                    })
                )
            } else {
                return this.contextNodeService.nodeView({ listen: false }).pipe(
                    switchMap(contextNodes => {
                        const activeProjects = contextNodes.map(cn => cn.contextData.projectId).filter(_ => _ ? true : false);

                        return collection.get().pipe(
                            map((docs: QuerySnapshot<unknown>) => {
                                return docs.docs.map((doc: QueryDocumentSnapshot<unknown>) => {
                                    const data = doc.data() as ProjectDTO;
                                    return this.projectDtoToProject(
                                        {
                                            ...(data as any),
                                            id: doc.id,
                                            created: data.created ? (data.created as firebase.firestore.Timestamp).toDate() : null,
                                        },
                                        activeProjects
                                    );
                                });
                            })
                        );

                    }));


            }
        } else {
            return of([]);
        }
    }

    deleteProject(project: Project): Observable<void> {
        return from(this.db.collection("projects").doc(project.id).update(this.projectToProjectDTO(project.delete())));
    }

    restoreProject(project: Project): Observable<void> {
        return this.contextNodeService.projectNodes(project.id).pipe(
            switchMap((nodes) => {
                return this.contextNodeService.linkNodesToNodeView(nodes.map((n) => n.id));
            }),
            switchMap((_) => {
                return this.editProject(project.restore());
            }),
            map((_) => undefined),
            take(1)
        );
    }

    shareProject(projectId: string, userIds: string[]): Observable<void> {

        return this.contextNodeService.projectNodes(projectId).pipe(
            switchMap((nodes) => {
                if (userIds.length > 0) {
                    return forkJoin(userIds.map(uId => this.contextNodeService.linkNodesToNodeView(nodes.map((n) => n.id), uId)));
                } else {
                    return of(undefined);
                }
            }),
            map((_) => undefined),
            take(1)
        );

    }

    /**
     * active projects are project in nodes in the treeview
     */
    activeProjects(): Observable<Project[]> {
        return this.contextNodeService.nodeView({ listen: false }).pipe(
            switchMap((nodes) => {
                // assuming project nodes are only in the root of the treeview
                const projectIds = nodes
                    .filter((n) => (n.type === "project" && n.contextData?.projectId ? true : false))
                    .map((n) => n.contextData.projectId);
                return forkJoin(projectIds.map((id) => this.project(id, projectIds, { listen: false }))).pipe(
                    map((ps) => ps.filter((p) => !p.deleted || !p.archived))
                );
            })
        );
    }

    project(id: string, activeProjects?: string[], options?: ProjectQueryOption): Observable<Project> {
        const docPointer = this.db
            .collection("projects")
            .doc(id);


        if (!activeProjects) {
            return this.contextNodeService.nodeView({ listen: false }).pipe(
                switchMap(contextNodes => {
                    activeProjects = contextNodes.map(cn => cn.contextData.projectId).filter(_ => _ ? true : false);
                    if (options && options.listen === true) {
                        return docPointer.snapshotChanges().pipe(
                            map((doc: Action<DocumentSnapshot<unknown>>) => {
                                const data = doc.payload.data() as any;
                                return this.projectDtoToProject({ ...data, id, created: data.created?.toDate() || null }, activeProjects);
                            })
                        );

                    } else {
                        return docPointer.get().pipe(
                            map((doc: any) => {
                                const data = doc.data() as any;
                                return this.projectDtoToProject({ ...data, id, created: data.created?.toDate() || null }, activeProjects);
                            })
                        );
                    }
                })
            );
        } else {
            if (options && options.listen === true) {
                return docPointer.snapshotChanges().pipe(
                    map((doc: Action<DocumentSnapshot<unknown>>) => {
                        const data = doc.payload.data() as any;
                        return this.projectDtoToProject({ ...data, id, created: data.created?.toDate() || null }, activeProjects);
                    })
                );

            } else {
                return docPointer.get().pipe(
                    map((doc: any) => {
                        const data = doc.data() as any;
                        return this.projectDtoToProject({ ...data, id, created: data.created?.toDate() || null }, activeProjects);
                    })
                );
            }
        }
    }

    editProject(project: Project): Observable<Project> {
        return from(
            this.db
                .collection("projects")
                .doc(project.id)
                .set(this.projectToProjectDTO(project))
        ).pipe(map((_) => project));
    }

    archiveProject(projectId: string): Observable<void> {
        return from(
            this.db
                .collection("projects")
                .doc(projectId)
                .update({ archived: true })
        );
    }

    addNewProject(id?: string, name?: string): Observable<Project> {
        const project = Project.DEFAULT_PROJECT.change({
            id: id ? id : this.db.createId(),
            created: new Date(),
            name: name ? name : "Projet",
        });

        return from(this.db.collection("projects").doc(project.id).set(this.projectToProjectDTO(project))).pipe(
            map((_) => project)
        );
    }

    private projectDtoToProject(projectDto: ProjectDTO, activeProjectIds: string[]): Project {
        let status: ProjectStatus;
        if (activeProjectIds.includes(projectDto.id)) {
            status = "active";
        } else if (projectDto.archived) {
            status = "archived";
        } else {
            status = "deleted";
        }
        return new Project({ ...projectDto, status, config: this.projectConfigDTOToProjectConfig(projectDto.config), created: projectDto.created as Date });
    }

    private projectToProjectDTO(project: Project): ProjectDTO {
        return {
            id: project.id,
            name: project.name,
            config: this.projectConfigToProjectConfigDTO(project.config),
            archived: project.archived,
            deleted: project.deleted,
            created: project.created,
        };
    }


    private projectConfigToProjectConfigDTO(projectConfig: PublicProjectConfiguration): PublicProjectConfigurationDTO {
        if (!projectConfig) {
            return null;
        }
        return {
            publicName: projectConfig.publicName || null,
            startDate: projectConfig.startDate || null,
            endDate: projectConfig.endDate || null,
            description: projectConfig.description || null,
            files: projectConfig.files || [],
            formData: projectConfig.formData || [],
        }
    }

    private projectConfigDTOToProjectConfig(projectConfigDTO: PublicProjectConfigurationDTO): PublicProjectConfiguration {
        return new PublicProjectConfiguration({
            publicName: projectConfigDTO?.publicName,
            startDate: projectConfigDTO?.startDate ? (projectConfigDTO.startDate as Timestamp).toDate() : null,
            endDate: projectConfigDTO?.endDate ? (projectConfigDTO.endDate as Timestamp).toDate() : null,
            description: projectConfigDTO?.description,
            files: projectConfigDTO?.files,
            formData: projectConfigDTO?.formData
        })
    }
}

export interface ProjectQueryOption {
    listen?: boolean;
    archived?: boolean;
    deleted?: boolean;
}

export interface ProjectDTO {
    id: string;
    name: string;
    config: PublicProjectConfigurationDTO;
    archived: boolean;
    deleted: boolean;
    created: Date | firebase.firestore.Timestamp;
}

export interface PublicProjectConfigurationDTO {
    publicName?: TranslatableLabel;
    startDate?: Date | Timestamp;
    endDate?: Date | Timestamp;
    description?: TranslatableLabel;
    files?: ProjectConfigPublicFile[];
    formData?: ProjectFormPublicDescriptor[];
}