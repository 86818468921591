import { Injectable } from '@angular/core';
import { catchError, fromEvent, map, Observable, of, take, tap } from 'rxjs';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';

@Injectable({
    providedIn: 'root'
})
export class LocalDatabaseService {

    private db: IDBDatabase;

    constructor() { }


    init(): Observable<any> {
        return new Observable<any>((subscriber) => {
            if (!this.db) {


                const openOrCreateDB = window.indexedDB.open('youlug_contacts', 1);

                openOrCreateDB.addEventListener('error', () => {
                    subscriber.error({ error: "Error loading local database" });
                });

                openOrCreateDB.addEventListener('success', () => {
                    console.log('Successfully opened youlug local database');
                    this.db = openOrCreateDB.result;
                    subscriber.next()
                });

                openOrCreateDB.addEventListener('upgradeneeded', (init: any) => {
                    this.db = init.target.result;
                    this.db.onerror = () => {
                        console.error('Error loading database.');
                    };
                    const tableContacts = this.db.createObjectStore('contacts', { keyPath: 'id' });
                    tableContacts.createIndex('id', 'id', { unique: true });
                });

            } else {
                console.log("LocalDB already open")
                subscriber.next();
            }
        });
    }

    set(collection: string, data: any): Observable<any> {
    return new Observable((observer) => {
        // Check if the database is initialized
        if (!this.db) {
            console.log("Database not initialized. Initializing now...");
            // Call init and then set the data once the database is ready
            this.init().subscribe({
                next: () => {
                    // Database initialized, now try setting the data again
                    this.set(collection, data).subscribe({
                        next: result => observer.next(result),
                        error: err => observer.error(err),
                        complete: () => observer.complete()
                    });
                },
                error: err => {
                    console.error("Failed to initialize the database", err);
                    observer.error(err);
                }
            });
        } else {
            // Proceed with setting data as the database is already initialized
            const transaction = this.db.transaction([collection], 'readwrite');
            const objectStore = transaction.objectStore(collection);
            let request;
            try {
                request = objectStore.put(data);
                request.onsuccess = () => {
                    observer.next(data);
                    observer.complete();
                };
                request.onerror = (err) => {
                    console.error(data);
                    console.error((err.target as any).error);
                    observer.error(`Error to save data: ${err.target.error}`);
                    observer.complete();
                };
            } catch (e) {
                console.error(e);
                console.error(data);
                observer.error(e);
            }
        }
    });
}


    get<T>(collection: string, id: string): Observable<T> {
        return new Observable((observer) => {
            if (this.db) {

                const transaction = this.db.transaction([collection], 'readwrite');
                const objectStore = transaction.objectStore(collection);

                let request = objectStore.get(id);

                request.onsuccess = () => {
                    observer.next(request.result);
                    observer.complete();
                }

                request.onerror = (err) => {
                    console.error((err.target as any).error);
                    observer.error(`Error to save  data`)
                    observer.complete();
                }
            } else {
                observer.next(undefined);
            }

        });
    }

    delete(collection: string, id: string): Observable<void> {
        return new Observable((observer) => {

            const transaction = this.db.transaction([collection], 'readwrite');
            const objectStore = transaction.objectStore(collection);

            let request = objectStore.delete(id);

            request.onsuccess = () => {
                observer.next();
                observer.complete();
            }

            request.onerror = (err) => {
                console.error((err.target as any).error);
                observer.error(`Error to save  data`)
                observer.complete();
            }

        });
    }

    collection(index: string): Observable<any[]> {
        return new Observable((observer) => {
            const transaction = this.db.transaction([index], 'readwrite');
            const objectStore = transaction.objectStore(index);
            const request = objectStore.getAll();

            request.onsuccess = () => {
                const data: any[] = request.result;
                observer.next(data);
                observer.complete();
            }

            request.onerror = (err) => {
                observer.error(`Error to get all ${index}: ${err}`)
            }
        });

    }

    countCollection(index: string): Observable<number> {
        return new Observable((observer) => {
            const transaction = this.db.transaction([index], 'readwrite');
            const objectStore = transaction.objectStore(index);
            const request = objectStore.count();

            request.onsuccess = () => {
                const data: number = request.result;
                observer.next(data);
                observer.complete();
            }

            request.onerror = (err) => {
                observer.error(`Error counting ${index}: ${err}`)
            }
        });
    }

    clearCollection(index: string): Observable<any> {
        return new Observable((observer) => {
            const transaction = this.db.transaction([index], 'readwrite');
            const objectStore = transaction.objectStore(index);
            const request = objectStore.clear();

            request.onsuccess = () => {
                observer.next();
                observer.complete();
            }

            request.onerror = (err) => {
                observer.error(`Error to get all students: ${err}`)
            }

        });
    }



}
