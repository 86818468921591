import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { FormElement } from 'src/modules/diversite/model/form/form-element/form-element';
import { SearchDefinition } from 'src/modules/diversite/model/search-definition';
import { SearchGroup } from 'src/modules/diversite/model/search-group';
import { SearchMeta } from 'src/modules/diversite/model/search-meta';
import { SearchOperation } from 'src/modules/diversite/model/search-operation';
import { BookmarkService } from 'src/modules/diversite/services/bookmark.service';
import { SearchFavoriteNode, SearchFavoriteNodeService } from 'src/modules/diversite/services/search-favorite-node.service';
import { TranslateService } from 'src/modules/diversite/services/translate.service';

import { ContextNodePaneActionsService } from '../../../context-node/context-node-pane-actions.service';

declare var $: any;

@Component({
    selector: "diversite-search-parameters",
    templateUrl: "./search-parameters.component.html",
    styleUrls: ["./search-parameters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchParametersComponent implements OnDestroy {
    @Input() projectId: string;
    @Input() contextNodeId: string;
    @Input() searchDefinition: SearchDefinition;
    @Input() favoriteNodes: SearchFavoriteNode[];
    @Input() searchMeta: SearchMeta;
    @Input() tabOpen: SearchParametersTab = "search";

    @Output() search = new EventEmitter<SearchDefinition>();
    @Output() changeTab = new EventEmitter<SearchParametersTab>();

    private disposeBag = new DisposeBag();



    constructor(
        private searchFavoriteNodeService: SearchFavoriteNodeService,
        private actionPaneService: ContextNodePaneActionsService,
        private bookmarkService: BookmarkService,
        private translateService: TranslateService,
        private db: AngularFirestore,
    ) { }
    ngOnInit(): void { }

    trackById(_: number, item: any): string {
        return item.id;
    }

    get lang(): string {
        return this.translateService.lang;
    }

    onSearch(): void {
        if (this.searchDefinition) {

            this.bookmarkService.saveNewBookmark(this.searchDefinition.id).subscribe(bookmark => {
                this.searchDefinition = this.searchDefinition.change({
                    bookmarkSpec: { ...this.searchDefinition.bookmarkSpec, bookmark }
                })
                this.search.emit(this.searchDefinition);
            }).disposedBy(this.disposeBag);
        } else {
            this.search.emit(this.searchDefinition);
        }
    }

    onChangeTab(tab: "search" | "classification"): void {
        if (this.tabOpen !== tab) {
            this.tabOpen = tab;
            this.changeTab.emit(this.tabOpen);
        }
    }

    addToFavorite(formElement: FormElement): void {
        this.searchFavoriteNodeService
            .addToFavorite(
                new SearchFavoriteNode({
                    formElement,
                    type: "search-field",
                })
            )
            .subscribe()
            .disposedBy(this.disposeBag);
    }


    resetSearch(): void {
        this.searchDefinition = this.searchDefinition.eraseReset();
        this.onSearch();
    }

    get project(): string {
        if (this.searchDefinition?.projectId) {
            return this.searchDefinition?.projectId;
        } else if (this.projectId) {
            return this.projectId;
        }
        return null;
    }

    onSearchParameterChange(searchParameter: SearchDefinition): void {
        this.searchDefinition = searchParameter;
        this.onSearch();
    }

    onEditGroup(searchGroup: SearchGroup, groupIndex: number): void {
        this.searchDefinition = this.searchDefinition.editGroupIndex(searchGroup, groupIndex);
        this.onSearch();
    }

    openFormPane(formId: string): void {
        this.actionPaneService.openFormPane(formId, this.contextNodeId);
    }

    onViewGroupResults(groupId: string): void {
        this.searchDefinition = this.searchDefinition.viewGroupResults(groupId);
        this.onSearch();
    }

    onProjectChange(projectId: string): void {
        this.searchDefinition = this.searchDefinition.change({ projectId })
    }

    addSearchOperationForIndex(index: string, value: string, grouIndex: number): void {
        const sop = new SearchOperation({
            id: this.db.createId(),
            operand: index,
            operator: "query",
            value,
        });
        this.searchDefinition = this.searchDefinition.replaceAddSearchOperationsForSearchGroup([sop], grouIndex);
        this.onSearch();
    }



    addOrSearchGroup(searchGroup: SearchGroup): void {
        this.searchDefinition = this.searchDefinition.addSearchGroup(
            new SearchGroup({ operator: "AND", searchOperations: searchGroup?.searchOperations || [] })
        );
    }

    removeSearchGroup(groupIndex: number): void {
        this.searchDefinition = this.searchDefinition.removeSearchGroupIndex(groupIndex);
        this.onSearch();
    }

    onNodeChange(node: SearchFavoriteNode): void {
        let newnodes = this.favoriteNodes.map((n) => (n.id === node.id ? node : n));
        this.searchFavoriteNodeService.saveFavorites(newnodes).subscribe();
    }
    onRemoveFavoriteNode(nodeId: string): void {
        let newnodes = this.favoriteNodes.filter((n) => n.id !== nodeId);
        this.searchFavoriteNodeService.saveFavorites(newnodes).subscribe();
    }

    onResizeFavorites(event: any): void {
        console.log(event);
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}

export type SearchParametersTab = "search" | "classification";