import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { map, Observable, of, tap } from 'rxjs';
import { FormElementInput, LiteralValue } from 'src/modules/diversite/model/form/form-element/form-element';
import { DataCatalogService } from 'src/modules/diversite/services/data-catalog.service';

@Component({
    selector: 'fillout-attribute-value',
    templateUrl: './attribute-value.component.html',
    styleUrl: './attribute-value.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttributeValueComponent {
    @Input() formElement: FormElementInput;
    @Input() value;
    @Input() lang;

    value$: Observable<LiteralValue>;



    constructor(private dataCatalogService: DataCatalogService, private saniter: DomSanitizer) { }

    ngOnInit(): void {
        // this.value$ = this.dataCatalogService.attributeValue(this.attributeName, this.value).pipe(map(v => v ? v : "-"))
        this.value$ = of(this.formElement.literalValue(this.value, this.lang)).pipe(
            map(lv => lv && lv.value != "" ? lv : { type: "string", value: "-" } as LiteralValue),
            tap(_ => console.log(_))
        );
    }

    sanitize(value: string): SafeHtml {
        console.log(value)
        return this.saniter.bypassSecurityTrustHtml(value);
    }

}
