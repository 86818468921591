import { TranslatableLabel } from "../services/data-catalog.service";

export interface PublicProjectConfigurationBuilder {
    publicName?: TranslatableLabel;
    startDate?: Date;
    endDate?: Date;
    description?: TranslatableLabel;
    files?: ProjectConfigPublicFile[];
    formData?: ProjectFormPublicDescriptor[];
}

export class PublicProjectConfiguration {
    private _publicName: TranslatableLabel;
    private _startDate: Date;
    private _endDate: Date;
    private _description: TranslatableLabel;
    private _files: ProjectConfigPublicFile[];
    private _formData: ProjectFormPublicDescriptor[];

    constructor(builder: PublicProjectConfigurationBuilder) {
        this._publicName = builder.publicName || { en: "", fr: "" };
        this._startDate = builder.startDate || null;
        this._endDate = builder.endDate || null;
        this._description = builder.description || { en: "", fr: "" };
        this._files = builder.files || [];
        this._formData = builder.formData || [];
    }

    get publicName(): TranslatableLabel {
        return this._publicName;
    }
    get startDate(): Date {
        return this._startDate;
    }
    get endDate(): Date {
        return this._endDate;
    }
    get description(): TranslatableLabel {
        return this._description;
    }
    get files(): ProjectConfigPublicFile[] {
        return this._files;
    }
    get formData(): ProjectFormPublicDescriptor[] {
        return this._formData;
    }

    change(builder: PublicProjectConfigurationBuilder): PublicProjectConfiguration {
        return new PublicProjectConfiguration({
            publicName: this._publicName,
            startDate: this._startDate,
            endDate: this._endDate,
            description: this._description,
            files: this._files,
            formData: this._formData,
            ...builder
        })
    }

}

export interface ProjectConfigPublicFile {
    name: TranslatableLabel;
    url: string;
}

export interface ProjectFormPublicDescriptor {
    formId: string;
    desc: TranslatableLabel;
}