<div [class.no-label]="!options.showLabel" class="clr-form-control" *ngIf="formElement">
    <label *ngIf="options.showLabel" class="clr-control-label">
        {{ formElement.label[lang] }}&nbsp;<span [hidden]="!formElement.required" class="required-star">*</span
        ><ng-content select="[tooltip-content]"></ng-content
    ></label>

    <div class="phones">
        <div *ngFor="let phone of phonesArray(); index as i" class="clr-control-container phone">
            (<input
                [readonly]="formElement.readonly"
                [required]="formElement.required"
                [(ngModel)]="phone.areaCode"
                autocomplete="tel-area-code"
                class="clr-input regionDigits"
                type="text"
                tabindex="0"
                maxlength="3"
                minlength="3"
                (keydown)="onlyAllowDigits($event)"
                (keyup)="onInputChange()"
            />)&nbsp;<input
                [readonly]="formElement.readonly"
                [required]="formElement.required"
                [(ngModel)]="phone.localPrefix"
                autocomplete="tel-local-prefix"
                class="clr-input first3digits"
                type="text"
                tabindex="0"
                maxlength="3"
                minlength="3"
                (keydown)="onlyAllowDigits($event)"
                (keyup)="onInputChange()"
            />-<input
                [readonly]="formElement.readonly"
                [required]="formElement.required"
                [(ngModel)]="phone.localSuffix"
                tabindex="0"
                autocomplete="tel-local-suffix"
                class="clr-input last4digits"
                type="text"
                maxlength="4"
                minlength="4"
                (keydown)="onlyAllowDigits($event)"
                (keyup)="onInputChange()"
            />
            <div class="phone-actions">
                <select (change)="onInputChange()" [(ngModel)]="phone.type">
                    <option value="">-</option>
                    <option value="cell">{{ label("phoneType.cell") }}</option>
                    <option value="home">{{ label("phoneType.home") }}</option>
                    <option value="work">{{ label("phoneType.work") }}</option>
                    <option value="agent">{{ label("phoneType.agent") }}</option>
                    <option value="other">{{ label("phoneType.other") }}</option>
                </select>
                <input
                    (keyup)="onInputChange()"
                    class="clr-input notes"
                    [(ngModel)]="phone.notes"
                    type="text"
                    [placeholder]="label('notes')"
                />
                <cds-icon *ngIf="value && value.length > 1" (click)="removePhone(i)" shape="times"></cds-icon>
            </div>
        </div>

        <a class="action-link" (click)="addPhone()">{{ label("add") }}</a>
    </div>
</div>
