<div *ngIf="isLogged" class="info-options">
    <div class="left">
        <clr-select-container>
            <select
                (change)="onPerspectiveProjectChange($event.target.value)"
                clrSelect
                name="attribute-perspective"
                [(ngModel)]="selectedProject"
            >
                <option value="">Perspective de projet</option>
                <option [value]="project.id" *ngFor="let project of projects$ | async; trackBy: trackById">
                    {{ project.name }}
                </option>
            </select>
        </clr-select-container>
        <ng-container *ngIf="forms$ | async as forms">
            <clr-select-container *ngIf="forms.length > 0">
                <select
                    (change)="onPerspectiveFormChange($event.target.value)"
                    clrSelect
                    name="attribute-perspective"
                    [(ngModel)]="selectedForm"
                    [disabled]="selectedProject === ''"
                >
                    <option value="">Perspective de formulaire</option>
                    <option [value]="form.id" *ngFor="let form of forms$ | async; trackBy: trackById">
                        {{ form.adminName }}
                    </option>
                </select>
            </clr-select-container>
            <small
                *ngIf="selectedProject !== '' && selectedForm !== ''"
                (click)="openFormPane()"
                class="action-link no-margin"
                >Voir le formulaire</small
            >

            <small *ngIf="forms.length === 0 && selectedProject !== ''" class="no-margin"
                >Ce membre a répondu à aucun formulaire de ce projet</small
            >
        </ng-container>
    </div>
</div>
<!-- <div class="right">
    <clr-checkbox-wrapper>
        <input type="checkbox" clrCheckbox name="show-empty-attributes" [(ngModel)]="showEmptyAttrs" />
        <label>Afficher les champs vide</label>
    </clr-checkbox-wrapper>
</div> -->

<ng-container>
    <div *ngIf="$attributeNodes | async as attributeNodes" class="attribute-nodes">
        <diversite-attribute-node
            *ngFor="let attributeNode of attributeNodes; trackBy: trackById"
            [hidden]="!(showEmptyAttrs || hasValue(attributeNode))"
            [attr.data-nodeid]="attributeNode.id"
            [showEmptyAttributes]="showEmptyAttrs"
            [contact]="contact"
            [editPerspectiveMode]="editPerspectiveMode"
            [attributeNode]="attributeNode"
            (sortTree)="onSortTree()"
            (attributeNodeChange)="onAttributeNodeChange($event)"
            (removeNode)="onRemoveNode($event)"
        ></diversite-attribute-node>
    </div>
</ng-container>

<a class="add-sub-folder" *ngIf="editPerspectiveMode" (click)="addFolder()" class="action-link add-folder"
    >Ajouter un dossier</a
>
