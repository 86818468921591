<div class="group">
    <div (click)="toggle()" *ngIf="group.title" class="group-title">
        <cds-icon shape="angle" [direction]="open ? 'down' : 'right'"></cds-icon>
        {{ group.title }} - {{ group.contactResults.length }} membres
    </div>
    <div class="contacts" [hidden]="!open">
        <diversite-contact-search-profile
            *ngFor="let contactData of group.contactResults; trackBy: trackByContactResult"
            [attr.data-contactid]="contactData.contactId"
            [classificationId]="classificationId"
            [contact]="contactData.contact"
            [highlights]="contactData.highlights"
            [height]="contactHeight(contactData.highlights)"
            [width]="contactWidth"
            [infoSize]="infoSize"
            (loaded)="onContactLoaded($event)"
            (selectContact)="onToggleSelection($event)"
            (viewProfile)="onViewProfile($event)"
            (editContact)="onEditContact($event)"
            (openFormPane)="onOpenFormPane($event, contactData.contactId)"
        ></diversite-contact-search-profile>
    </div>
</div>
