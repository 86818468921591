import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, debounceTime, skip, take } from 'rxjs';
import { StaticLabels, StaticLabelsService } from 'src/modules/core/services/static-labels.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { FormPhones } from 'src/modules/diversite/model/form/form-element/form-phones';
import { Phone } from 'src/modules/diversite/model/phone';
import { DEFAULT_OPTIONS, FormElementGenericOptions } from '../../form-element.component';

@Component({
    selector: 'fillout-phones',
    templateUrl: './phones.component.html',
    styleUrl: './phones.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhonesComponent {
    private translationId = "CelZVqUNI67cf5LN7tTH";

    @Input() lang: string;
    @Input() formElement: FormPhones;
    @Input() value: Phone[] = [];
    @Input() options: FormElementGenericOptions = { ...DEFAULT_OPTIONS };
    @Output() responseChange = new EventEmitter<Phone[]>();

    private translationLabels: StaticLabels = {};
    delayedChanges$ = new BehaviorSubject<void>(undefined);

    phones: { [key: string]: Phone } = {};


    private disposeBag = new DisposeBag();

    constructor(private staticLabelsService: StaticLabelsService, private chRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.delayedChanges$
            .pipe(debounceTime(250), skip(1))
            .subscribe(() => {
                this.responseChange.emit(Object.values(this.phones));
            })
            .disposedBy(this.disposeBag);

        this.staticLabelsService
            .labelsForComponent(this.translationId)
            .pipe(take(1))
            .subscribe((labels) => {
                this.translationLabels = labels;
                this.chRef.markForCheck();
            })
            .disposedBy(this.disposeBag);
    }



    ngOnChanges(changes: SimpleChanges): void {
        this.setEditableModel(changes.value.currentValue)
    }

    private setEditableModel(value: Phone[]): void {
        this.phones = {}
        if (value && value.length > 0) {
            value.forEach((element, index) => {
                this.phones[index] = element
            });
        } else {
            this.phones = { 0: { areaCode: "", localPrefix: "", localSuffix: "", type: "", notes: "" } };
        }

    }

    addPhone(): void {
        if (this.value && this.value.length > 0) {
            this.value = [...this.value, { areaCode: "", localPrefix: "", localSuffix: "", type: "", notes: "" }];
        } else {
            this.value = [this.phones[0], { areaCode: "", localPrefix: "", localSuffix: "", type: "", notes: "" }];
        }
        this.setEditableModel(this.value);
        this.chRef.detectChanges();
    }

    removePhone(index: number): void {
        this.value = this.value.filter((_, i) => i !== index);
        this.setEditableModel(this.value);
        this.chRef.detectChanges();
    }

    onInputChange(): void {
        this.delayedChanges$.next();
    }

    phonesArray(): Phone[] {
        return Object.values(this.phones);

    }

    label(labelId: string): string {
        if (
            this.translationLabels &&
            this.translationLabels[labelId] &&
            this.translationLabels[labelId][this.lang]
        ) {
            return this.translationLabels[labelId][this.lang];
        }
        return labelId;
    }


    onlyAllowDigits(event: KeyboardEvent): void {
        if (
            !(
                (
                    event.key === "Tab" ||
                    event.key === "Backspace" || // backspace
                    event.key === "Delete" || // delete
                    (event.keyCode >= 35 && event.keyCode <= 40) || // arrow keys/home/end
                    (event.keyCode >= 48 && event.keyCode <= 57) || // numbers on keyboard
                    (event.keyCode >= 96 && event.keyCode <= 105)
                ) // number on keypad
            )
        ) {
            event.preventDefault(); // Prevent character input
            event.stopPropagation();
        }
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
