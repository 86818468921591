import { FormElementDTO } from "src/modules/diversite/mappers/module-mapper.service";
import { TranslatableLabel } from "src/modules/diversite/services/data-catalog.service";
import { Phone } from "../../phone";
import { FormElement, FormElementError, FormElementInput, FormElementType, LiteralValue } from "./form-element";
interface Builder extends FormElementDTO {
    score?: number;
}
export class FormPhones implements FormElement, FormElementInput {
    private _id: string;
    private _nameDescriptor: TranslatableLabel;
    private _description: TranslatableLabel;
    private _name: string;
    private _nameDisabled: boolean;
    private _type: FormElementType = "phones";
    private _label: TranslatableLabel;
    private _readonly: boolean;
    private _required: boolean;
    private _score: number;
    private _deleted: boolean;

    static defaultElement(): FormPhones {
        return new FormPhones({
            nameDescriptor: { fr: "Champ téléphone(s)" },
            label: { fr: "Nouveau champ téléphone(s)" },
        });
    }

    constructor(builder: Builder) {
        this._id = builder.id;
        this._nameDescriptor = builder.nameDescriptor || {};
        this._description = builder.description || {};
        this._name = builder.name || "";
        this._nameDisabled = builder.nameDisabled === true ? true : false;
        this._label = builder.label;

        this._readonly = builder.readonly === true ? true : false;
        this._required = builder.required === true ? true : false;
        this._score = builder.score || 0;
        this._deleted = builder.deleted === true ? true : false;
    }

    literalValue(value: any[]): LiteralValue {
        // areaCode: string;
        // localPrefix: string;
        // localSuffix: string;
        if (value && value.length > 0) {
            return {
                type: "html",
                value: value.map(v => {
                    return `(${v.areaCode}) ${v.localPrefix ? v.localPrefix : ""}-${v.localSuffix ? v.localSuffix : ""}`
                }).join("<br>")
            };
        }
    }

    get id(): string {
        return this._id;
    }
    get nameDescriptor(): TranslatableLabel {
        return this._nameDescriptor;
    }
    get description(): TranslatableLabel {
        return this._description;
    }

    get name(): string {
        return this._name;
    }
    get nameDisabled(): boolean {
        return this._nameDisabled;
    }
    get type(): FormElementType {
        return this._type;
    }
    get label(): TranslatableLabel {
        return this._label;
    }
    get readonly(): boolean {
        return this._readonly;
    }
    get required(): boolean {
        return this._required;
    }
    get score(): number {
        return this._score;
    }
    get deleted(): boolean {
        return this._deleted;
    }

    change(builder: FormElementDTO): FormPhones {
        return new FormPhones({
            id: this.id,
            nameDescriptor: this._nameDescriptor,
            description: this.description,
            name: this.name,
            nameDisabled: this.nameDisabled,
            label: this.label,
            required: this.required,
            readonly: this.readonly,
            score: this.score,
            deleted: this.deleted,
            ...builder,
        });
    }

    isInput(): true {
        return true;
    }

    validate(value: Phone[]): FormElementError[] {
        if (this.deleted) {
            return null;
        }
        if (!this.required) {
            return null;
        }
        // TODO !!!!
    }

    toDto(): FormElementDTO {
        return {
            id: this.id,
            nameDescriptor: this._nameDescriptor || null,
            description: this.description || null,
            name: this.name || null,
            nameDisabled: this.nameDisabled,
            type: this.type,
            label: this.label || null,
            required: this.required || null,
            readonly: this.readonly || null,
            score: this.score,
            deleted: this.deleted,
        };
    }
}