<div class="project-config">
    <clr-input-container>
        <label>Nom de projet interne</label>
        <input
            (change)="onInternalNameChange($event.target.value)"
            [value]="internalName"
            clrInput
            placeholder=""
            name="internal-name"
        />
    </clr-input-container>

    <div class="dates">
        <clr-date-container>
            <label>Date d'ouverture</label>
            <input
                type="date"
                autocomplete="off"
                clrDate
                name="date-start"
                [clrDate]="dates.start"
                (clrDateChange)="onDateChange('start', $event)"
            />
        </clr-date-container>
        <clr-date-container>
            <label>Date de fermeture</label>
            <input
                type="date"
                autocomplete="off"
                clrDate
                name="date-end"
                [clrDate]="dates.end"
                (clrDateChange)="onDateChange('end', $event)"
            />
        </clr-date-container>
    </div>

    <label class="clr-control-label">Nom public du projet</label>
    <clr-input-container>
        <input
            clrInput
            (change)="onPublicNameChange('fr', $event.target.value)"
            [value]="publicName.fr"
            placeholder="fr"
            name="public-name-fr"
        />
    </clr-input-container>
    <clr-input-container>
        <input
            clrInput
            (change)="onPublicNameChange('en', $event.target.value)"
            [value]="publicName.en"
            placeholder="en"
            name="public-name-en"
        />
    </clr-input-container>

    <label class="clr-control-label">Description du project publique</label>
    <diversite-text-html-editor
        [placeholder]="'fr'"
        [contentHtml]="desc.fr"
        (contentChange)="onDescriptionChange('fr', $event)"
    ></diversite-text-html-editor>
    <diversite-text-html-editor
        [placeholder]="'en'"
        [contentHtml]="desc.en"
        (contentChange)="onDescriptionChange('en', $event)"
    ></diversite-text-html-editor>

    <label class="clr-control-label">Fichier(s) public(s)</label>
    <div class="clr-file-wrapper">
        <label class="clr-control-label">
            <a class="btn btn-sm btn-primary">Ajouter un fichier</a>
            <input (change)="onInputChange($event)" class="clr-file" type="file" multiple />
        </label>
    </div>

    <small [hidden]="files.length > 0"><em>Aucun fichier ajouté</em></small>

    <div class="files">
        <div class="file" *ngFor="let file of files; trackBy: trackByUrl">
            <input type="text" [value]="file.url" />
            <div class="actions">
                <cds-icon (click)="removeFile(file.url)" shape="trash"></cds-icon>
            </div>
        </div>
    </div>

    <div class="forms">
        <clr-select-container>
            <label>Formulaire(s) à intégrer</label>
            <select clrSelect name="options" [(ngModel)]="toBeAddedFormId">
                <option value="">-</option>
                <option [value]="form.id" *ngFor="let form of forms; trackBy: trackById">
                    {{ form.adminName }}
                </option>
            </select>
        </clr-select-container>
        <button [disabled]="toBeAddedFormId === ''" (click)="addForm()" class="btn btn-sm btn-icon">
            <cds-icon shape="plus"></cds-icon>
        </button>

        <div class="form" *ngFor="let data of selectedForms; trackBy: trackByDescriptor">
            <div class="info">
                <label class="clr-control-label">{{ data.form.adminName }}</label>

                <div class="actions">
                    <cds-icon (click)="removeForm(data.form.id)" shape="trash"></cds-icon>
                </div>
            </div>
            <div class="form-description">
                <diversite-text-html-editor
                    [placeholder]="'fr'"
                    [contentHtml]="formDesc(data.form.id, 'fr')"
                    (contentChange)="onFormDescriptionChange($event, 'fr', data.form.id)"
                ></diversite-text-html-editor>
                <diversite-text-html-editor
                    [placeholder]="'en'"
                    [contentHtml]="formDesc(data.form.id, 'en')"
                    (contentChange)="onFormDescriptionChange($event, 'en', data.form.id)"
                ></diversite-text-html-editor>
            </div>
        </div>
    </div>
</div>
