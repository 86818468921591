<div class="clr-form-control">
    <label class="clr-control-label" *ngIf="options.showLabel"
        >{{ formElement.label[lang] }}&nbsp;<span [hidden]="!formElement.required" class="required-star">*</span
        ><ng-content select="[tooltip-content]"></ng-content
    ></label>
    <fillout-google-places
        [adressValue]="adressValue"
        (placeSelected)="onPlaceSelected($event)"
    ></fillout-google-places>
</div>

<div class="places">
    <div class="place" *ngFor="let place of placesArray(); trackBy: trackById">
        <a [href]="place.url" target="_blank" class="place-name">{{ place.address.name }}</a>
        <div class="place-actions">
            <select [(ngModel)]="place.placeType">
                <option value="">-</option>
                <option value="home">{{ label("placeType.home") }}</option>
                <option value="work">{{ label("placeType.work") }}</option>
                <option value="agent">{{ label("placeType.agent") }}</option>
                <option value="other">{{ label("placeType.other") }}</option>
            </select>
            <input [(ngModel)]="place.notes" [placeholder]="label('notes')" class="clr-input notes" type="text" />
            <cds-icon shape="times" (click)="removePlace(place)"></cds-icon>
        </div>
    </div>
</div>
