<div>
    <ul class="main-menu">
        <li *hasAccess="{ type: 'segment', segment: 'activityOperation', permissions: ['read'] }">
            <a (click)="viewActivities()"><cds-icon shape="bolt"></cds-icon>Activitées</a>
        </li>

        <li class="logout-link">
            {{ username }}<br /><a class="no-margin action-link" (click)="logout()">Déconnexion</a>
        </li>
    </ul>
</div>
<div *hasAccess="{ type: 'segment', segment: 'globalSearch', permissions: ['read'] }" class="root">
    <cds-divider></cds-divider>
    <ul>
        <li>
            <a class="btn btn-sm btn-primary" (click)="openGenericSearchPane()">Rechercher</a>
        </li>
        <!-- <li>
            <a class="action-link" (click)="openGenericSearchPane('createdAt')">Date de creation</a>
        </li>
        <li>
            <a class="action-link" (click)="openGenericSearchPane('lastUpdatedAt')">Date de modification</a>
        </li> -->
    </ul>
</div>

<div *ngIf="!projectFocusNode" (contextmenu)="onRightClick($event)" class="root">
    <cds-divider></cds-divider>
    <div class="projects-header">
        <small>Projets</small>
        <clr-tooltip>
            <cds-icon (click)="openProjectsPane()" clrTooltipTrigger shape="list"></cds-icon>
            <clr-tooltip-content [clrPosition]="'left'" [clrSize]="'sm'"> Voir tout les projets </clr-tooltip-content>
        </clr-tooltip>
    </div>
    <div *hasAccess="{ type: 'segment', segment: 'projectOperation', permissions: ['write'] }" class="actions">
        <span role="tooltip" class="tooltip tooltip-bottom-right">
            <button (click)="onAddProjectClick()" type="button" class="btn btn-icon btn-sm btn-primary">
                <cds-icon shape="plus"></cds-icon>
            </button>
            <span class="tooltip-content">Ajouter un projet</span>
        </span>
    </div>

    <ng-container *ngFor="let n of nodes$ | async; trackBy: trackById">
        <diversite-context-node
            *hasAccess="{ type: 'project', projectId: n.contextData?.projectId, permissions: ['read'] }"
            [nodeId]="n.id"
            [projectId]="n.contextData?.projectId"
            (focusProject)="onFocusProject(n)"
        ></diversite-context-node>
    </ng-container>
</div>

<div class="focus-project" *ngIf="projectFocusNode">
    <diversite-context-node
        *hasAccess="{ type: 'project', projectId: projectFocusNode.contextData?.projectId, permissions: ['read'] }"
        [isOpen]="true"
        [nodeId]="projectFocusNode.id"
        [projectId]="projectFocusNode.contextData?.projectId"
    ></diversite-context-node>

    <cds-icon (click)="onCloseFocus()" class="close" shape="times"></cds-icon>
</div>
