<div *ngIf="formElement" class="form-element-edition">
    <span
        #labelElement
        class="edit-label"
        contenteditable
        (keyup)="onLabelChange()"
        [innerHTML]="tempFormElement.label[lang]"
    ></span>
    <ng-content select="[tooltip]"></ng-content>
    <div>
        (<input readonly size="3" class="fake-input digit3" type="number" />)&nbsp;
        <input readonly size="3" class="fake-input digit3" type="number" />-
        <input readonly size="4" class="fake-input digit4" type="number" />&nbsp;<select class="fake-input" readonly>
            <option value="1">Type</option></select
        >&nbsp;<input class="fake-input" type="text" placeholder="Notes" readonly />&nbsp;<cds-icon
            shape="times"
        ></cds-icon>
    </div>
    <a class="action-link disabled">Add</a>
</div>
