import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, combineLatest, fromEvent, merge, Observable, of } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Contact } from 'src/modules/diversite/model/contact';
import { ContactImage } from 'src/modules/diversite/model/contact-image';
import { ContactPerspective } from 'src/modules/diversite/model/contactPerspective';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { ContactHighlight } from 'src/modules/diversite/services/elasticsearch.service';
import {
    FacePositionService,
    ImageDispositionOption,
    ImageLayout,
} from 'src/modules/diversite/services/face-position.service';
import {
    AttributeShown,
    SearchProfileVisibleAttributesService,
} from 'src/modules/diversite/services/search-profile-visible-attributes.service';

import { ClassificationGroup } from 'src/modules/diversite/model/classification-group';
import { SearchClassificationService } from 'src/modules/diversite/services/search-classification.service';
import { AddPhotosForContactEvent } from '../contact-view/contact-view.component';
import { AttributeChangeEvent } from './search-profile-attribute/search-profile-attribute.component';

const CARD_FACE_OPTIONS: ImageDispositionOption = {
    containerWidth: 240,
    containerHeight: 300,
    finalFaceHeight: 110,
};

declare let $: any;
@Component({
    selector: "diversite-contact-search-profile",
    templateUrl: "./contact-search-profile.component.html",
    styleUrls: ["./contact-search-profile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactSearchProfileComponent implements OnChanges, OnDestroy {
    @Input() contactId: string;
    @Input() classificationId: string;
    @Input() contact: Contact;
    @Input() infoSize = 110;
    @HostBinding('style.height.px') @Input() height = 300;
    @HostBinding('style.width.px') @Input() width = 200;
    @Input() highlights: ContactHighlight[] = [];
    @Input() cardFaceDispositionOptions = CARD_FACE_OPTIONS;
    @Input() selectedFieldToShow: string[] = [];
    @Input() removable = true;

    @Output() viewProfile = new EventEmitter<ContactPerspective>();
    @Output() selectContact = new EventEmitter<Contact>();
    @Output() loaded = new EventEmitter<ContactLoadedEvent>();
    @Output() contactDefaultImage = new EventEmitter<ChangeContactDefaultImageEvent>();
    @Output() addPhotos = new EventEmitter<AddPhotosForContactEvent>();
    @Output() editContact = new EventEmitter<Contact>();
    @Output() openFormPane = new EventEmitter<string>();

    contactPerspective$: Observable<ContactPerspective>;
    isInViewport = false;
    defaultImage: ImageLayout;
    viewProfileWindow: ContactPerspective;
    thumbnails: ImageLayout[] = [];
    private _disposeBag = new DisposeBag();
    htmlFocusInfo = new Map<string, string>();
    shownAttributes$: Observable<AttributeShown[]>;
    highlights$ = new BehaviorSubject<ContactHighlight[]>([]);


    contactSelection$: Observable<ClassificationGroup>;
    selected$: Observable<boolean>;
    selectedColor$: Observable<string>;


    zoom = 1;
    isCompact: boolean = false;

    private clickEvents$: Observable<MouseEvent>;

    constructor(
        private contactService: ContactService,
        private facePosition: FacePositionService,
        private hostElement: ElementRef,
        private visibleAttrService: SearchProfileVisibleAttributesService,
        private classificationService: SearchClassificationService,
        private chRef: ChangeDetectorRef
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.contactPerspective$ && this.contactId) {
            this.contactPerspective$ = this.contactPerspectiveObs(this.contactId);
            this.setVisibleAttributesObs();
        }

        if (changes.contact?.currentValue && !this.contactId) {
            const contact: Contact = changes.contact?.currentValue;
            this.contactPerspective$ = of(this.contactPerspective(contact));
            this.setVisibleAttributesObs();
            this.loaded.emit({ contact, element: this.hostElement });
            this.onLoaded(contact.defaultPerspective);
        }

        if (changes.highlights) {
            if (changes.highlights.currentValue) {
                this.highlights$.next(changes.highlights.currentValue)
            } else {
                this.highlights$.next([])
            }
        }

        if (changes.width?.currentValue && changes.width.currentValue !== changes.width.previousValue) {
            this.zoom = this._zoom(changes.width?.currentValue);
            this.isCompact = this._isCompact(changes.width?.currentValue);
        }
    }

    private setVisibleAttributesObs(): void {
        this.shownAttributes$ = this.contactPerspective$.pipe(switchMap(cp => {
            return combineLatest([
                this.visibleAttrService.attributes().pipe(map(attrs => {
                    return attrs.filter(attr => {
                        const attributeHighlighted = this.highlights$.value.find(attributeHightlited => attributeHightlited.attributeName === attr.attributeId) ? true : false;
                        return cp.contact.hasAttribute(attr.attributeId) && !attributeHighlighted
                    })
                })),
                this.highlights$.pipe(
                    map(chs => {
                        return chs.map(ch => { return { attributeId: ch.attributeName, highlight: ch.highlight } });
                    })
                )]).pipe(map(x => x[0].concat(x[1])));
        }))
    }

    private onLoaded(cp: ContactPerspective): void {
        this.handleClickAndDoubleClick(cp)
    }

    valueForIndex(cp: ContactPerspective, attributeName: string): string {
        const value = cp.contact.getAttributeValue(attributeName);
        return value;
    }



    onOpenFormPane(formId: string): void {
        this.openFormPane.emit(formId);
    }

    private setImages(contactPerspective: ContactPerspective): void {
        this.thumbnails = this.imagesThumbnailSelector(contactPerspective);
        this.defaultImage = this.generateDefaultImage(contactPerspective);
    }

    get mode(): "contain" | "cover" {
        if (this.defaultImage) {
            return this.defaultImage.width > this.defaultImage.height ? "cover" : "contain";
        }
        return "contain";
    }

    private handleClickAndDoubleClick(cp: ContactPerspective): void {
        if (!this.clickEvents$) {
            const element = $(".card", this.hostElement.nativeElement).get(0)
            const clickEvent = fromEvent<MouseEvent>(element, "click");
            const dblClickEvent = fromEvent<MouseEvent>(element, "dblclick");
            this.clickEvents$ = merge(clickEvent, dblClickEvent).pipe(debounceTime(250));
            this.clickEvents$.subscribe((event) => {
                if (event.type === "click") {
                    this.select(cp.contact);
                } else {
                    this.openProfile(cp);
                }
            })
                .disposedBy(this._disposeBag);
        }

    }

    private _zoom(width: number): number {
        const value = width / 2 / 100;
        if (value > 1) {
            return 1;
        } else if (value < 0.6) {
            return 0.6;
        } else {
            return value;
        }
    }

    private contactPerspectiveObs(contactId: string): Observable<ContactPerspective> {
        return this.contactService.contactById(contactId).pipe(
            map((c) => {
                return this.contactPerspective(c);
            }), tap(cp => {
                if (cp) {
                    this.loaded.emit({ contact: cp.contact, element: this.hostElement });
                    this.onLoaded(cp);
                }
            })
        );
    }

    private contactPerspective(c: Contact): ContactPerspective {
        if (c) {
            const cp = c.defaultPerspective;
            if (!this.contactSelection$) {
                this.contactSelection$ = this.classificationService.classificationForContact(this.classificationId, c.id);
                this.selectedColor$ = this.contactSelection$.pipe(map(classificationGroup => classificationGroup ? classificationGroup.color : null)).pipe(tap(_ => {
                }));
                this.selected$ = this.selectedColor$.pipe(map(color => color ? true : false), tap(_ => {
                    setTimeout(() => { this.chRef.detectChanges() })
                }));
            }
            this.setImages(cp);
            return cp;
        }
    }


    private _isCompact(width: number): boolean {
        return width < 130;
    }

    private generateDefaultImage(contactPerspective: ContactPerspective): ImageLayout {
        const defaultImage = contactPerspective.defaultImage;

        if (defaultImage) {
            return this.facePosition.mapContactImageToImageLayout(
                defaultImage,
                this.cardFaceDispositionOptions,
                contactPerspective.imageDispositionSpecs.find((imgSpec) => imgSpec.imageId === defaultImage.id)
            );
        }

        return null;
    }

    onImageSelected(cp: ContactPerspective, index: number): void {
        if (index || index === 0) {
            this.defaultImage = this.generateDefaultImage(cp);
            this.contactDefaultImage.emit({
                contactId: this.contactId ? this.contactId : this.contact?.id,
                index,
            });
            this.chRef.markForCheck();
        }
    }

    private imagesThumbnailSelector(contactPerspective: ContactPerspective): ImageLayout[] {
        return contactPerspective?.contact?.images.map((image) => this.thumbnail(image));
    }

    private openProfile(cp: ContactPerspective): void {
        this.viewProfile.emit(cp);
    }

    private select(contact: Contact): void {
        // if (!this.classificationService.disabled) {
        //     if (this.classificationService.selectedNodeId && this.classificationService.selectedNodeId !== "") {
        //         const updatedContact: Contact = this.contactPerspective$.value.contact.toggleContextNode(this.classificationService.selectedNodeId);
        //         this.contactPerspective$.next(updatedContact.defaultPerspective);
        //         // TO IMPLEMENT

        //         // this.editContact.emit(updatedContact);
        //         // this.assignContextNodeService.toggleClassificationForContact(updatedContact, this.classificationService.selectedNodeId);
        //     }
        // } else {
        this.selectContact.emit(contact);
        // }
    }

    private thumbnail(contactImage: ContactImage): ImageLayout {
        if (contactImage) {
            return { url: contactImage.url, rotate: contactImage.rotate, id: contactImage.id };
        }
    }

    trackByAttrData(i: number, searchedAttribute: AttributeShown): string {
        return `${searchedAttribute.attributeId}`;
    }

    onAddPhotos(event: AddPhotosForContactEvent): void {
        this.addPhotos.emit(event);
    }

    onAttributeValueChange(response: AttributeChangeEvent, contact: Contact): void {
        const editedContact = contact.changeAttribute(response.attributeId, response.value);
        this.contactService
            .editContact(editedContact)
            .subscribe()
            .disposedBy(this._disposeBag);
    }

    ngOnDestroy(): void {
        this._disposeBag.dispose();
    }
}

export interface ChangeContactDefaultImageEvent {
    contactId: string;
    index: number;
}

export interface ContactLoadedEvent {
    contact: Contact;
    element: ElementRef;
}


