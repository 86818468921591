import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, catchError, debounceTime, delay, filter, finalize, skip, throwError } from 'rxjs';
import { FileUploadGenericService, UploadFilesResponse } from 'src/modules/core/services/file-upload.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Form } from 'src/modules/diversite/model/form/form';
import { Project } from 'src/modules/diversite/model/project';
import { ProjectConfigPublicFile, PublicProjectConfiguration } from 'src/modules/diversite/model/public-project-configuration';
import { TranslatableLabel } from 'src/modules/diversite/services/data-catalog.service';

@Component({
    selector: 'diversite-project-config-pane-content',
    templateUrl: './project-config-pane-content.component.html',
    styleUrl: './project-config-pane-content.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectConfigPaneContentComponent {

    @Input() project: Project;
    @Input() forms: Form[];
    @Output() projectChange = new EventEmitter<Project>();

    @Output() close = new EventEmitter<void>();

    internalName: string = "";
    dates = { start: null, end: null };
    desc: TranslatableLabel = { en: "", fr: "" };
    publicName: TranslatableLabel = { en: "", fr: "" };

    files: ProjectConfigPublicFile[] = []


    toBeAddedFormId: string = "";
    selectedForms: PublicFormDescriptor[] = [];

    saveConfig$ = new BehaviorSubject<void>(undefined);

    private disposeBag = new DisposeBag();

    constructor(private fileUploadService: FileUploadGenericService, private chRef: ChangeDetectorRef) { }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes.project?.currentValue) {
            const p: Project = changes.project?.currentValue;
            this.internalName = p.name;
            this.dates.start = p.config?.startDate;
            this.dates.end = p.config?.endDate;
            this.desc = p.config?.description || { en: "", fr: "" };
            this.publicName = p.config?.publicName || { en: "", fr: "" };
            this.files = p.config?.files || [];
            this.selectedForms = p.config?.formData.map(d => {
                return {
                    form: this.forms.find(f => f.id === d.formId),
                    desc: d.desc
                }
            }) || [];
        }
    }

    ngOnInit(): void {
        this.saveConfig$.pipe(
            skip(1),
            debounceTime(3000)
        ).subscribe(() => {

            const config: PublicProjectConfiguration = new PublicProjectConfiguration({
                publicName: this.publicName,
                startDate: this.dates.start,
                endDate: this.dates.end,
                description: this.desc,
                files: this.files,
                formData: this.selectedForms.map(selectedFormData => {
                    return {
                        formId: selectedFormData.form.id,
                        desc: selectedFormData.desc
                    }
                })
            })
            this.project = this.project.change({ name: this.internalName }).changeConfig(config);
            this.projectChange.emit(this.project)
        }).disposedBy(this.disposeBag);
    }


    onInputChange(event, retry = false): void {
        if (event.target.files.length > 0) {

            const files = event.target.files;
            this.fileUploadService
                .uploadFiles(Array.from(files))
                .pipe(
                    delay(500),
                    filter(response => response.percentage === 100),
                    catchError((err) => {
                        if (!retry) {
                            this.onInputChange(event, true);
                            return null;
                        }
                        return throwError(err);
                    }),
                    finalize(() => { })
                )
                .subscribe((uploadResponse: UploadFilesResponse) => {
                    if (uploadResponse.percentage === 100) {
                        this.files = [...this.files, ...uploadResponse.urls.map(url => {
                            return {
                                url,
                                name: {
                                    en: url,
                                    fr: url
                                }
                            }
                        })]
                        this.saveConfig$.next();
                        this.chRef.detectChanges();
                    }
                })
                .disposedBy(this.disposeBag);
        }

    }

    trackById(_: number, entity: any): string {
        return entity.id;
    }

    trackByUrl(_: number, entity: any): string {
        return entity.url;
    }

    trackByDescriptor(_: number, entity: PublicFormDescriptor): string {
        return entity.form.id
    }

    onInternalNameChange(name: string): void {
        this.internalName = name;
        this.saveConfig$.next();
    }

    onDescriptionChange(lang: string, content: string): void {
        this.desc[lang] = content;
        this.saveConfig$.next();
    }

    onPublicNameChange(lang: string, content: string): void {
        this.publicName[lang] = content;
        this.saveConfig$.next();
    }

    onDateChange(startEnd: string, date: Date): void {
        this.dates[startEnd] = date;
        this.saveConfig$.next();
    }

    removeFile(url: string): void {
        this.files = this.files.filter(f => f.url !== url);
        this.saveConfig$.next();
    }

    removeForm(formId: string): void {
        this.selectedForms = [...this.selectedForms.filter(f => f.form.id !== formId)]
        this.saveConfig$.next();
    }

    onClose(): void {
        this.close.emit();
    }

    addForm(): void {
        const form = this.forms.find(f => f.id === this.toBeAddedFormId);
        if (form) {
            this.selectedForms = [...this.selectedForms, { form, desc: { en: "", fr: "" } }];
        }
        this.toBeAddedFormId = "";
        this.saveConfig$.next();
    }

    formDesc(formId: string, lang: string): string {
        return this.selectedForms.find(data => data.form.id === formId).desc[lang];
    }

    onFormDescriptionChange(content: string, lang: string, formId: string): void {
        this.selectedForms = this.selectedForms.map(d => {
            if (d.form.id === formId) {
                const desc = { ...d.desc };
                desc[lang] = content;
                return { ...d, desc }
            }
            return d
        })
        this.saveConfig$.next();
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}

export interface PublicFormDescriptor {
    form: Form;
    desc: TranslatableLabel;
}