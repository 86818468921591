import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

import { Contact } from '../../model/contact';
import { Form } from '../../model/form/form';
import { Project } from '../../model/project';
import {
    AttributeNode,
    AttributeNodesContext,
    AttributePerspectiveType,
    AttributesContextPerspectiveService,
} from '../../services/attributes-context-perspective.service';
import { FormService } from '../../services/form.service';
import { ProjectService } from '../../services/projects.service';
import { TranslateService } from '../../services/translate.service';
import { ContextNodePaneActionsService } from '../context-node/context-node-pane-actions.service';

declare var $: any;

@Component({
    selector: "diversite-attribute-nodes",
    templateUrl: "./attribute-nodes.component.html",
    styleUrls: ["./attribute-nodes.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributeNodesComponent implements OnInit, OnChanges {
    @Input() context: AttributeNodesContext;
    @Input() contact: Contact;

    $attributeNodes: Observable<AttributeNode[]>;
    showEmptyAttrs = false;

    editPerspectiveMode = false;

    private _attributeNodes: AttributeNode[];

    selectedPerspective: AttributePerspectiveType = "global";
    selectedProject: string = "";
    selectedForm: string = "";

    projects$: Observable<Project[]>;
    forms$: Observable<Form[]>;

    contextChange$ = new BehaviorSubject<AttributeNodesContext>(undefined);

    private firstLoad = true;

    constructor(
        private db: AngularFirestore,
        private attrContextPerspectiveService: AttributesContextPerspectiveService,
        private projectService: ProjectService,
        private formService: FormService,
        private authService: AuthService,
        private translateService: TranslateService,
        private actionsPaneService: ContextNodePaneActionsService,
        private host: ElementRef,
        private chRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.projects$ = this.projectService.activeProjects();

        this.$attributeNodes = this.attrContextPerspectiveService.perspectiveForContext().pipe(
            tap((nodes) => {
                this._attributeNodes = nodes;
            })
        );
        this.contextChange$.pipe(share()).subscribe((context) => {
            this.context = context;
            if (context?.projectId) {
                this.selectedProject = context.projectId;
                this.forms$ = this.formService.formForProject(context.projectId).pipe(map((forms) => forms.filter((f) => this.contact.hasAttribute(`recruitment_${f.id}`))));
            } else {
                this.forms$ = of([]);
            }
            if (context?.formId) {
                this.selectedForm = context.formId;
            }


            this.chRef.detectChanges();
            this.setSortable();
        });
    }

    ngAfterViewInit(): void {
        this.setSortable();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.context?.currentValue) {
            this.contextChange$.next(changes.context.currentValue);
        }
    }

    onRemoveNode(nodeId: string): void {
        // this.attrContextPerspectiveService
        //     .saveAttributePerspective(
        //         this._attributeNodes.filter((an) => an.id !== nodeId),
        //         this.contextChange$.value
        //     )
        //     .subscribe();
    }

    openFormPane(): void {
        return this.actionsPaneService.openFormPane(this.selectedForm, null, this.selectedProject, this.contact.id);
    }

    get isLogged(): boolean {
        return this.authService.isLoggedIn;
    }

    private setSortable(): void {
        setTimeout(() => {
            $(".attribute-nodes", this.host.nativeElement)
                .first()
                .sortable({
                    handle: ".handle-option",
                    connectWith: ".attribute-nodes",
                    helper: "clone",
                    placeholder: "sortable-placeholder",
                    update: (event, ui) => {
                        this.onSortTree();
                    },
                });
        }, 1000);
    }

    addFolder(): void {
        // this.attrContextPerspectiveService
        //     .saveAttributePerspective(
        //         [
        //             ...this._attributeNodes,
        //             new AttributeNode({ id: this.db.createId(), name: "Nouveau dossier", children: [], open: false }),
        //         ],
        //         this.contextChange$.value
        //     )
        //     .subscribe();
    }

    onSortTree(): void {
        // const nodesRefs = this.processChild();

        // let flattennedAttributes = new Map<string, AttributeNode>();
        // this.deepFlatten(this._attributeNodes, flattennedAttributes);

        // const reorderedAttributeNodes = this.mapAttributeNodes(nodesRefs, flattennedAttributes);
        // this.attrContextPerspectiveService
        //     .saveAttributePerspective(reorderedAttributeNodes, this.contextChange$.value)
        //     .subscribe();
    }

    private mapAttributeNodes(
        nodesRefs: DropSortDefinitionDefinition[],
        dictionary: Map<string, AttributeNode>
    ): AttributeNode[] {
        return nodesRefs.map((node) => {
            const attrNode = dictionary.get(node.id);
            if (node.children) {
                return attrNode.change({ children: this.mapAttributeNodes(node.children, dictionary) });
            } else {
                return attrNode;
            }
        });
    }

    private deepFlatten(origin: AttributeNode[], toAppend: Map<string, AttributeNode>): any {
        origin.forEach((element) => {
            if (element.children && element.children.length > 0) {
                this.deepFlatten(element.children, toAppend);
            }
            if (!toAppend.has(element.id)) {
                toAppend.set(element.id, element);
            }
        });
    }

    private processChild(node?: HTMLElement): DropSortDefinitionDefinition[] {
        const parentNode = node ? $(".attribute-nodes", node).first() : $(".attribute-nodes").first();
        const nodes = $("> diversite-attribute-node", parentNode).toArray();
        return nodes.map((n) => {
            return { id: n.getAttribute("data-nodeid"), children: this.processChild(n) };
        });
    }

    trackById(index: number, entity: any): string {
        return entity.id;
    }
    hasValue(attrNode: AttributeNode): boolean {
        return attrNode.children || (attrNode.attributeId && this.contact.getAttributeValue(attrNode.attributeId));
    }

    resetAttributeNode(): void {
        this.attrContextPerspectiveService.resetAttributeNodesForContext(this.context).subscribe();
    }

    onPerspectiveProjectChange(projectId: string): void {
        this.selectedForm = "";

        if (projectId === "") {
            this.contextChange$.next(undefined);
        } else {
            // generate forms for project selected
            this.contextChange$.next({ projectId });
        }

        // this.attrContextPerspectiveService.updateLastSelectedContext(this.contextChange$.value);
    }

    onPerspectiveFormChange(formId: string): void {
        if (formId === "") {
            this.contextChange$.next({ ...this.contextChange$.value, formId: null });
        } else {
            this.contextChange$.next({ ...this.contextChange$.value, formId });
        }

        // this.attrContextPerspectiveService.updateLastSelectedContext(this.contextChange$.value);
    }

    onAttributeNodeChange(attributeNode: AttributeNode): void {
        // let newnodes = this._attributeNodes.map((n) => (n.id === attributeNode.id ? attributeNode : n));
        // this.attrContextPerspectiveService.saveAttributePerspective(newnodes, this.contextChange$.value).subscribe();
    }

    get lang(): string {
        return this.translateService.lang;
    }
}

export interface DropSortDefinitionDefinition {
    id: string;
    children: DropSortDefinitionDefinition[];
}
