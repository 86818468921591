import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Form } from 'src/modules/diversite/model/form/form';
import { Pane } from 'src/modules/diversite/model/pane';
import { Project } from 'src/modules/diversite/model/project';
import { ContextNodeService } from 'src/modules/diversite/services/context-node.service';
import { FormService } from 'src/modules/diversite/services/form.service';
import { ProjectService } from 'src/modules/diversite/services/projects.service';

@Component({
    selector: 'diversite-project-config-pane-content-container',
    templateUrl: './project-config-pane-content-container.component.html',
    styleUrl: './project-config-pane-content-container.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectConfigPaneContentContainerComponent {
    @Input() pane: Pane;
    @Output() close = new EventEmitter<void>();

    project$: Observable<Project>;
    forms$: Observable<Form[]>;
    private disposeBag = new DisposeBag();

    constructor(private formService: FormService, private projectService: ProjectService, private contextNodeService: ContextNodeService) { }

    ngOnInit(): void {
        this.project$ = this.projectService.project(this.pane.projectId, null, { listen: true })
        this.forms$ = this.formService.formForProject(this.pane.projectId);
    }


    onProjectChange(project: Project): void {
        this.contextNodeService.projectNodes(project.id, { listen: false }).pipe(switchMap(p => {

            return this.contextNodeService.editNode(p[0].change({
                name: project.name
            })).pipe(switchMap(_ => {
                return this.projectService.editProject(project);
            }))
        })).subscribe().disposedBy(this.disposeBag);
    }


    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}

