import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, share, startWith, switchMap, take, tap } from 'rxjs/operators';
import { focusPane } from 'src/app/core/functions';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';

import { SystemRequestService } from 'src/app/services/system-request.service';
import { Contact } from '../../model/contact';
import { ContextNode, ContextNodeType } from '../../model/context-node';
import { Form } from '../../model/form/form';
import { Report } from '../../model/report';
import { SearchDefinition } from '../../model/search-definition';
import { ContactContextNodeAssignService } from '../../services/contact-context-node-assign.service';
import { ContextMenuElement, ContextMenuService } from '../../services/context-menu.service';
import { ContextNodeService } from '../../services/context-node.service';
import { DragDropActionService } from '../../services/drag-drop-action.service';
import { FormService } from '../../services/form.service';
import { ProjectService } from '../../services/projects.service';
import { ReportService } from '../../services/report.service';
import { SearchContextService } from '../../services/search-context.service';
import { Package, SharedPackageService } from '../../services/shared-folder.service';
import { TranslateService } from '../../services/translate.service';
import { ContextNodePaneActionsService, MenuActionCallbackAction } from './context-node-pane-actions.service';

declare var $: any;
@Component({
    selector: "diversite-context-node",
    templateUrl: "./context-node.component.html",
    styleUrls: ["./context-node.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextNodeComponent implements OnChanges, OnDestroy {
    @Input() nodeId: string;
    @Input() projectId: string;
    @Input() parentNodeId: string;
    @Input() isOpen = false;
    @Output() loaded = new EventEmitter<ContextNode>();
    @Output() focusProject = new EventEmitter<string>();

    @ViewChild("contextMenuElement") contextMenuElement: ElementRef;
    @ViewChild("nodeElement") nodeElement: ElementRef;
    @ViewChild("draggableNode") dragHandle: ElementRef;
    @ViewChild("nodeTitleElement") nodeTitleElement: ElementRef;

    searchParameters$: Observable<SearchDefinition>;

    node$: Observable<ContextNode>;


    readonly contactsChange$ = new BehaviorSubject<boolean>(true)
    contacts$: Observable<Contact[]>;
    forms$: Observable<Form[]>;
    reports$: Observable<Report[]>;
    packages$: Observable<Package[]>;

    lastUpdatedPublishedFormFormProject$: Observable<Form>;

    isContactOpen = false;
    isFormOpen = false;
    isReportsOpen = false;
    isSharedFolderOpen = false;

    contactsLoading$: Observable<boolean>;

    private _nodeIcon = "";

    private disposeBag = new DisposeBag();

    constructor(
        private actionsService: ContextNodePaneActionsService,
        private dragDropActionService: DragDropActionService,
        private contextNodeService: ContextNodeService,
        private searchContextService: SearchContextService,
        private assignContextNodeService: ContactContextNodeAssignService,
        private formService: FormService,
        private contextMenuService: ContextMenuService,
        private reportService: ReportService,
        private translateService: TranslateService,
        private projectService: ProjectService,
        private sharedPackageService: SharedPackageService,
        private systemRequestService: SystemRequestService,
        private db: AngularFirestore,
        private chRef: ChangeDetectorRef
    ) { }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes.nodeId?.currentValue && !this.node$) {
            this.node$ = this.contextNodeService.node(changes.nodeId.currentValue).pipe(
                tap((node) => {
                    if (node) {
                        this._nodeIcon = this.shapeForType(node.type);
                        if (node.type === "search") {
                            this.searchParameters$ = this.searchContextService.searchDefinition(
                                node.contextData.searchContextId,
                                { listen: true }
                            );
                        }

                        setTimeout(() => {
                            this.setDraggable(node);
                            this.setDroppable(node);
                        }, 2000);


                        if (!this.lastUpdatedPublishedFormFormProject$ && node.type === "project") {
                            this.lastUpdatedPublishedFormFormProject$ = this.formService.lastUpdatedPublishedForm(this.projectId)
                        }
                    }
                })
            );

            this.forms$ = this.node$.pipe(
                switchMap((n) => combineLatest(n.contextData.formIds.map((fId) => this.formService.form(fId))))
            );

            this.reports$ = this.node$.pipe(
                switchMap((n) =>
                    combineLatest(
                        n.contextData.reportIds.map((fId) => this.reportService.reportById(fId, { listen: true }))
                    )
                )
            );

            this.packages$ = this.node$.pipe(
                switchMap((n) =>
                    combineLatest(
                        n.contextData.sharedPackages.map((packageId: string) => this.sharedPackageService.package(packageId, { listen: true }))
                    )
                ),
                map(packages => {
                    return packages.filter(_ => _);
                })
            );


        }


        if (changes.isOpen) {
            if (changes.isOpen.currentValue === true) {
                this.toggleOpenNode(true);
            } else {
                this.toggleOpenNode(false);
            }
        }

    }

    onViewRespondents(form: Form): void {
        this.actionsService.openSearchPane(
            SearchDefinition.DEFAULT_SEARCH_DEFINITION_FORM(this.db.createId(), form.id).change({ projectId: this.projectId || null }),
            this.projectId,
            this.nodeId
        ).subscribe().disposedBy(this.disposeBag);
    }

    openProjectConfigurationTab(node: ContextNode): void {
        this.actionsService.openProjectConfigurationPane(this.projectId, this.nodeId).subscribe().disposedBy(this.disposeBag);
    }

    onEditPackage(pckage: Package): void {
        this.sharedPackageService.editPackage(pckage).subscribe().disposedBy(this.disposeBag);
    }

    onDeletePackage(node: ContextNode, pckage: Package): void {
        this.contextNodeService.editNode(node.unassignSharedPackage(pckage.id)).pipe(switchMap(n => {
            return this.sharedPackageService.deletePackage(pckage)
        })).subscribe().disposedBy(this.disposeBag);
    }


    toggleOpenNode(isOpen: boolean) {
        this.isOpen = isOpen;
        if (this.isOpen && !this.contacts$) {
            this.contacts$ = this.assignContextNodeService.contactsForNodeId(this.nodeId).pipe(share());
            this.contactsLoading$ = this.contacts$.pipe(map(_ => false), startWith(true));
        }
    }

    nodeShape(): string {
        return this._nodeIcon;
    }

    setNormalIcon(node: ContextNode): void {
        this._nodeIcon = this.shapeForType(node.type);
    }
    setDragIcon(node: ContextNode): void {
        this._nodeIcon = this.nodeDraggable(node) ? "drag-handle" : this.shapeForType(node.type);
    }



    private setDroppable(node: ContextNode): void {
        if (node.type !== "search-parameters") {
            this.chRef.detectChanges();
            setTimeout(() => {
                const node = $(this.nodeElement.nativeElement);
                node.droppable({
                    greedy: true,
                    tolerance: "pointer",
                    drop: (e: any) => {
                        this.dragDropActionService
                            .dropDragAction({ nodeId: this.nodeId, projectId: this.projectId })
                            .pipe(take(1))
                            .subscribe((response) => {
                                if (response.success) {
                                    this.toggleOpenNode(true);
                                }
                            })
                            .disposedBy(this.disposeBag);
                    },
                });
            }, 250);
        }
    }

    private setDraggable(node: ContextNode): void {
        if (this.nodeDraggable(node) && this.dragHandle) {
            const containment = $(this.nodeElement.nativeElement).closest(".pane-content.treeview").get(0);

            $(this.nodeElement.nativeElement).draggable({
                appendTo: containment,
                handle: this.dragHandle.nativeElement,
                scroll: true,
                containment,
                helper: () => {
                    const node = $(this.nodeTitleElement.nativeElement).get(0).cloneNode(true);
                    return node;
                },
                start: (e, ui) => {
                    this.dragDropActionService.startDragAction({
                        nodeId: this.nodeId,
                    });
                },
            });
        }
    }

    nodeHasStaticFolderOrChildren(node: ContextNode): boolean {
        let i = 0;

        // if (node.hasContacts) {
        //     i = i + 1;
        // }
        if (node.hasForms) {
            i = i + 1;
        }
        if (node.hasReports) {
            i = i + 1;
        }
        if (node.children && node.children.length > 0) {
            i = i + 1;
        }
        return i > 1;
    }

    nodeDraggable(node: ContextNode): boolean {
        return this.contextNodeService.allowedDropForMovingNode(node.type).length > 0;
    }

    onKeyDown(event: KeyboardEvent) {
        event.stopPropagation();
    }

    onNodeNameChange(node: ContextNode, value: string): void {
        let obs: Observable<unknown> = this.contextNodeService.editNode(node.change({ name: value }));
        if (node.type === "project" && node.contextData?.projectId) {
            obs = obs.pipe(
                switchMap((n) => this.projectService.project(node.contextData?.projectId)),
                switchMap((p) => {
                    return this.projectService.editProject(p.change({ name: value }));
                })
            );
        }

        obs.subscribe((_) => { }).disposedBy(this.disposeBag);
    }

    trackByString(_: number, str: string): string {
        return str;
    }

    onDblClickHeader(node: ContextNode): void {
        if (node.type === "project") {
            this.focusProject.emit(this.projectId);
        }
    }

    shapeForType(type: ContextNodeType): string {
        switch (type) {
            case "folder":
                return "folder";
            case "search":
                return "search";
            case "search-parameters":
                return "layers";
            case "project":
                return "briefcase";
            case "reject":
                return "ban";
            case "preselection":
                return "folder";
            default:
                break;
        }
    }

    openSearchTab(node: ContextNode): void {
        this.actionsService.openSearchPaneForNode(node, this.projectId).subscribe((pane) => {
            focusPane(pane.id);
        }).disposedBy(this.disposeBag);
    }

    openClassificationTab(node: ContextNode): void {
        // this.actionsService.openClassificationPane(node, this.projectId).subscribe(pane => {
        //     focusPane(pane.id);
        // }).disposedBy(this.disposeBag)

        this.actionsService.openSearchPane(SearchDefinition.DEFAULT_SEARCH_DEFINITION(this.db.createId()), node.contextData?.projectId, node.id, "classification").subscribe().disposedBy(this.disposeBag)
    }

    // openParameters(node: ContextNode): void {
    //     this.actionsService.openSearchParametersPane(node, this.projectId);
    // }

    onRightClick(event: PointerEvent, node: ContextNode): void {
        this.contextMenuService.openContextMenu(this.optionsForNode(node), event);
        event.preventDefault();
        event.stopPropagation();
    }

    isExpandable(node: ContextNode): boolean {
        return (
            (node && node.children && node.children.length > 0) || node.type === "search-parameters"
        );
    }

    optionsForNode(node: ContextNode): ContextMenuElement[] {
        return this.actionsService.menuActionsForNode({ node, projectId: this.projectId }, (action: MenuActionCallbackAction) => {
            if (action === "generate-report") {
                this.contextNodeService.createReportForNode(node).subscribe().disposedBy(this.disposeBag);
            }
            if (action === "add-idfig-list") {
                this.actionsService.openActionPaneAtIndex(
                    "add-idfig-list",
                    1,
                    { contextNodeId: node.id, projectId: this.projectId }
                ).subscribe().disposedBy(this.disposeBag);
            }
            this.toggleOpenNode(true);
        });
    }

    trackById(_: number, obj: any): string {
        return obj.id;
    }

    get lang(): string {
        return this.translateService.lang;
    }

    onFocus(event: any) {
        event.preventDefault();
        event.stopPropagation();
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
