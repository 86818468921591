import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, debounceTime, skip, take } from 'rxjs';
import { StaticLabels, StaticLabelsService } from 'src/modules/core/services/static-labels.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { FormPlaces } from 'src/modules/diversite/model/form/form-element/form-places';
import { Place } from 'src/modules/diversite/model/place';
import { DEFAULT_OPTIONS, FormElementGenericOptions } from '../../form-element.component';

@Component({
    selector: 'fillout-places',
    templateUrl: './places.component.html',
    styleUrl: './places.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlacesComponent {
    private translationId = "oP4Yu4TtldIQWRFYaAKh";
    @Input() lang: string;
    @Input() formElement: FormPlaces;
    @Input() value: Place[] = [];
    @Input() options: FormElementGenericOptions = { ...DEFAULT_OPTIONS };
    @Output() responseChange = new EventEmitter<Place[]>();

    private translationLabels: StaticLabels = {};
    delayedChanges$ = new BehaviorSubject<void>(undefined);

    adressValue: string = "";

    places: { [key: string]: Place } = {};

    private disposeBag = new DisposeBag();

    constructor(private staticLabelsService: StaticLabelsService, private chRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.delayedChanges$
            .pipe(debounceTime(250), skip(1))
            .subscribe(() => {
                this.responseChange.emit(Object.values(this.places));
            })
            .disposedBy(this.disposeBag);


        this.staticLabelsService
            .labelsForComponent(this.translationId)
            .pipe(take(1))
            .subscribe((labels) => {
                this.translationLabels = labels;
                this.chRef.markForCheck();
            })
            .disposedBy(this.disposeBag);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setEditableModel(changes.value.currentValue)
    }

    private setEditableModel(value: Place[]): void {
        this.places = {}
        if (value && value.length > 0) {
            value.forEach((element, index) => {
                this.places[index] = element
            });
        }
    }

    placesArray(): Place[] {
        return Object.values(this.places);
    }

    onPlaceSelected(place: Place): void {
        if (this.value) {
            this.value = [...this.value, place];
        } else {
            this.value = [place];
        }

        this.adressValue = "";

        this.setEditableModel(this.value);
        this.delayedChanges$.next();
    }
    onInputChange(): void {
        this.delayedChanges$.next();
    }

    trackById(_: number, entity: any): string {
        return entity.id;
    }

    label(labelId: string): string {
        if (
            this.translationLabels &&
            this.translationLabels[labelId] &&
            this.translationLabels[labelId][this.lang]
        ) {
            return this.translationLabels[labelId][this.lang];
        }
        return labelId;
    }

    removePlace(place: Place): void {
        this.value = this.value.filter((p) => p.id !== place.id);
        this.setEditableModel(this.value);
        this.onInputChange()
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
