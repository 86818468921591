import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { debounceTime, skip } from "rxjs/operators";
import { DisposeBag } from "src/modules/core/utilities/dispose-bag";
import { FormPhone } from "src/modules/diversite/model/form/form-element/form-phone";
import { Phone } from "src/modules/diversite/model/phone";

import { DEFAULT_OPTIONS, FormElementGenericOptions } from "../../form-element.component";

const UNDEFINED_PHONE: Phone = {
    areaCode: null,
    localPrefix: null,
    localSuffix: null,
};
@Component({
    selector: "fillout-phone",
    templateUrl: "./phone.component.html",
    styleUrls: ["./phone.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneComponent implements OnInit, OnChanges {
    @Input() lang: string;
    @Input() formElement: FormPhone;
    @Input() value: Phone = UNDEFINED_PHONE;
    @Input() options: FormElementGenericOptions = { ...DEFAULT_OPTIONS };
    @Output() responseChange = new EventEmitter<Phone>();
    delayedChanges$ = new BehaviorSubject<void>(undefined);

    areaCode: string;
    localPrefix: string;
    localSuffix: string;

    private _disposeBag = new DisposeBag();

    constructor() { }

    ngOnInit(): void {
        this.delayedChanges$
            .pipe(debounceTime(250), skip(1))
            .subscribe(() => {
                this.responseChange.emit({
                    areaCode: this.areaCode || null,
                    localPrefix: this.localPrefix || null,
                    localSuffix: this.localSuffix || null,
                });
            })
            .disposedBy(this._disposeBag);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value && !changes.value.currentValue) {
            this.value = UNDEFINED_PHONE;
        }
        if (changes.value && changes.value.currentValue) {
            this.areaCode = changes.value.currentValue.areaCode || null;
            this.localPrefix = changes.value.currentValue.localPrefix || null;
            this.localSuffix = changes.value.currentValue.localSuffix || null;
        }
    }

    onInputChange(): void {
        this.delayedChanges$.next();
    }

    onlyAllowDigits(event: KeyboardEvent): void {
        if (
            !(
                (
                    event.key === "Tab" ||
                    event.key === "Backspace" || // backspace
                    event.key === "Delete" || // delete
                    (event.keyCode >= 35 && event.keyCode <= 40) || // arrow keys/home/end
                    (event.keyCode >= 48 && event.keyCode <= 57) || // numbers on keyboard
                    (event.keyCode >= 96 && event.keyCode <= 105)
                ) // number on keypad
            )
        ) {
            event.preventDefault(); // Prevent character input
            event.stopPropagation();
        }
    }
}
