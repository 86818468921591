import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, isDevMode, OnInit, Output, ViewChild } from '@angular/core';
import { Place } from 'src/modules/diversite/model/place';


declare var google: any;

@Component({
    selector: 'fillout-google-places',
    templateUrl: './google-places.component.html',
    styleUrl: './google-places.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GooglePlacesComponent implements OnInit, AfterViewInit {
    @Input() adressType: string = 'address'; // 'establishment' / 'address' / 'geocode'
    @Output() placeSelected: EventEmitter<Place> = new EventEmitter();

    @ViewChild('addresstext') addresstext: any;



    apiKey = isDevMode() ? 'AIzaSyAYyuGmZcsTWVfivAHwYkSRhrFMltnLfG0' : 'AIzaSyBvsOiAQt3nTIww5gv49XMsvVJChStEX-4';

    @Input() adressValue: string = "";


    constructor() { }

    ngOnInit(): void {
        // Load the Google Maps JavaScript API dynamically
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places`;

        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
    }

    ngAfterViewInit(): void {
        // Initialize the map after the view is initialized and the API has loaded
        const script = document.querySelector(`script[src^="https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places"]`);
        script?.addEventListener('load', () => {
            this.init();
        });
    }

    private init() {
        this.getPlaceAutocomplete();
    }

    private getPlaceAutocomplete() {
        const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, {
            languages: ['fr-CA', "en"],
            // componentRestrictions: { country: 'US' },
            types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
        });
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();
            this.invokeEvent(place);
        });
    }

    private invokeEvent(place: any) {

        const placeObj: Place = {
            id: place['place_id'],
            address: {
                name: place['name'],
                completeAddress: place['formatted_address'],
                htmlAddress: place['adr_address']
            },
            placeType: "",
            notes: "",
            url: place['url'],
            position: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            }
        };
        this.placeSelected.emit(placeObj);
        this.adressValue = "";
    }
}
