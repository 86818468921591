import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Observable } from "rxjs";
import { switchMap, take, tap } from "rxjs/operators";
import { AttributeIndex } from "src/modules/diversite/model/contact-profile-definition";
import { AttributeModule, ModulesAttributesService } from "src/modules/diversite/services/modules-attributes.service";

declare var $: any;

@Component({
    selector: "app-modules",
    templateUrl: "./modules.component.html",
    styleUrls: ["./modules.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModulesComponent implements OnInit {
    constructor(
        private modulesAttributesService: ModulesAttributesService,
        private db: AngularFirestore,
        private chRef: ChangeDetectorRef
    ) { }

    addOpen = false;

    formElementTypes = [
        "textbox",
        "radio",
        "datetime",
        "number",
        "dropdown",
        "union",
        "phone",
        "phones",
        "address",
        "checkbox",
        "textarea",
        "imageupload",
        "videoupload",
        "fileupload",
        "voiceupload",
        "boolean",
        "weight",
        "height",
        "places",
    ];

    tempFormElement: AttributeIndex;

    modules$: Observable<AttributeModule[]>;

    ngOnInit(): void {
        this.tempFormElement = this.defaultFormElement();
        this.tempFormElement.index = this.db.createId();

        this.modules$ = this.modulesAttributesService.getModules().pipe(
            tap((modules) => {
                setTimeout(() => {
                    this.chRef.detectChanges();
                    $(".form-element-reorder").sortable({
                        handle: ".handle",
                        update: (event, ui) => {
                            const orders: { order: number; index: string }[] = $("[data-index]", event.target)
                                .toArray()
                                .map((tr, i) => {
                                    return { order: i, index: tr.getAttribute("data-index") };
                                });

                            this.updateAttributesOrder(orders);
                        },
                    });
                }, 250);
            })
        );
    }

    onOpenChange(isOpen: boolean, attributeIndex = this.defaultFormElement()): void {
        this.addOpen = isOpen;
        this.tempFormElement = attributeIndex;

        if (isOpen && this.tempFormElement.formField?.options) {
            setTimeout(() => {
                $(".option-reorder").sortable({
                    handle: ".handle-option",
                    update: (event, ui) => {
                        const orders: { order: number; index: string }[] = $("[data-option-id]", event.target)
                            .toArray()
                            .map((tr, i) => {
                                return { order: i, index: tr.getAttribute("data-option-id") };
                            });
                        this.tempFormElement.formField.options = orders.map((o) =>
                            this.tempFormElement.formField.options.find(
                                (tempFormElement) => tempFormElement.id === o.index
                            )
                        );
                    },
                });
            }, 250);
        }
    }

    private defaultFormElement(): AttributeIndex {
        return {
            index: this.db.createId(),
            label: {},
            defaultDescription: false,
            order: Infinity,
            formField: {
                type: undefined,
                required: false,
            },
        };
    }

    updateAttributesOrder(orders: { order: number; index: string }[]): void {
        this.db
            .collection("systemData")
            .doc("definitions")
            .get()
            .pipe(
                switchMap((doc: any) => {
                    const data = doc.data() as any;
                    const attributeIndexes = data.profile.attributes;
                    const newAttributes = attributeIndexes.map((attr) => {
                        const orderUpdated = orders.find((order) => order.index === attr.index);
                        if (orderUpdated) {
                            attr.order = orderUpdated.order;
                        }
                        return attr;
                    });

                    return this.db
                        .collection("systemData")
                        .doc("definitions")
                        .set({
                            ...data,
                            profile: {
                                ...data.profile,
                                attributes: newAttributes,
                            },
                        });
                }),
                take(1)
            )
            .subscribe(() => {
                // window.location.reload();
            });
    }

    addOption(): void {
        if (!this.tempFormElement.formField.options) {
            this.tempFormElement.formField.options = [];
        }
        this.tempFormElement.formField.options = [
            ...this.tempFormElement.formField.options,
            { id: this.db.createId(), label: {} },
        ];
    }

    removeOption(optionId: string): void {
        this.tempFormElement.formField.options = this.tempFormElement.formField.options.filter(
            (option) => option.id !== optionId
        );
    }

    submitAttributeIndex(): void {
        this.db
            .collection("systemData")
            .doc("definitions")
            .get()
            .pipe(
                switchMap((doc: any) => {
                    const data = doc.data() as any;
                    const attributeIndexes = data.profile.attributes;
                    const existingIndex = attributeIndexes.find((attr) => attr.index === this.tempFormElement.index);
                    if (existingIndex) {
                        return this.db
                            .collection("systemData")
                            .doc("definitions")
                            .set({
                                ...data,
                                profile: {
                                    ...data.profile,
                                    attributes: attributeIndexes.map((attr) =>
                                        attr.index === this.tempFormElement.index ? this.tempFormElement : attr
                                    ),
                                },
                            });
                    } else {
                        return this.db
                            .collection("systemData")
                            .doc("definitions")
                            .set({
                                ...data,
                                profile: {
                                    ...data.profile,
                                    attributes: [...attributeIndexes, this.tempFormElement],
                                },
                            });
                    }
                }),
                take(1)
            )
            .subscribe((_) => {
                window.location.reload();
            });
    }

    isValid(): boolean {
        if (this.tempFormElement.index && this.tempFormElement.index.trim() === "") {
            return false;
        }
        if (
            this.tempFormElement.label &&
            (!this.tempFormElement.label.en || this.tempFormElement.label.en.trim() === "") &&
            (!this.tempFormElement.label.fr || this.tempFormElement.label.fr.trim() === "")
        ) {
            return false;
        }

        if (this.tempFormElement.formField && !this.tempFormElement.formField.type) {
            return false;
        }
        if (
            this.tempFormElement.formField &&
            (this.tempFormElement.formField.type === "dropdown" ||
                this.tempFormElement.formField.type === "checkbox" ||
                this.tempFormElement.formField.type === "radio") &&
            (!this.tempFormElement.formField.options ||
                Object.keys(this.tempFormElement.formField.options).length === 0)
        ) {
            return false;
        }

        return true;
    }

    // formElementOptions(formElement: AttributeIndex): FormOption[] {
    //     const keys = Object.keys(formElement.formField.options);

    //     return formElement.formField.options && keys.length > 0
    //         ? keys.map((id) => {
    //               return { id, label: formElement.formField.options[id] };
    //           })
    //         : [];
    // }
}
